import { useMutation, useReactiveVar } from "@apollo/client";
import {
    faAnglesLeft,
    faAnglesRight,
    faClock,
    faCreditCard,
    faMessage,
    faUser,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { Link, Navigate, useLocation } from "react-router-dom";
import styled from "styled-components";
import {
    hasRefreshIntervalId,
    isMinimizeNavigationBar,
    setRefreshIntervalId,
} from "../apollo";
import { Banner } from "../components/Banner";
import { Bookings } from "../components/bookings/Bookings";
import { HEADER_HEIGHT } from "../components/Header";
import { MemberPasswordChange } from "../components/member/memberPasswordChange";
import { Messages } from "../components/messages/Messages";
import { PageTitle } from "../components/PageTitle";
import { AccountInfoPopup } from "../components/popup/AccountInfoPopup";
import { MessageSettingPopup } from "../components/popup/MessageSettingPopup";
import { PhoneCertPopup } from "../components/popup/PhoneCertPopup";
import { SystemCancelRegScheduleBookingPopup } from "../components/popup/SystemCancelRegScheduleBookingPopup";
import { StatisticsBookings } from "../components/statistics/bookings/StatisticsBookings";
import { evictCacheRootQuery, isMemberSignUp } from "../global";
import { UPDATE_MEMBER_ACTIVE_MUTATION } from "../graphqls/mutations";
import useMember, { useSystem } from "../hooks/useMember";
import {
    toggleNavigationMinimize,
    useNavigationWidth,
} from "../hooks/useNavigationWidth";
import routes, { consoleRoutes } from "../routes";
import { ButtonSpan } from "../styles";
import {
    MemberInactiveReason,
    MemberStatus,
} from "../__generated__/globalTypes";
import { updateMemberActive } from "../__generated__/updateMemberActive";
import { RefreshButton } from "../components/common/RefreshButton";
import { isIOS, openUrlNewTab } from "../utils";
import { UpdateHideProducts } from "../components/products/UpdateHideProducts";
import { Kakao } from "./Kakao";
import { KakaoMessagePopup } from "../components/popup/KakaoMessagePopup";

const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    background-color: ${(props) => props.theme.background};
`;

const Body = styled.div`
    display: flex;
    width: 100%;
`;

const Navigation = styled.div<{ navigationWidth: string }>`
    display: flex;
    flex-direction: column;
    width: ${(props) => props.navigationWidth};
    min-width: ${(props) => props.navigationWidth};
    background-color: ${(props) => props.theme.navigationBackground};
    color: ${(props) => props.theme.fontDarkGray};
    user-select: none;
    min-height: calc(100vh - ${HEADER_HEIGHT});
`;

const NavigationInner = styled.div`
    display: flex;
    flex-direction: column;
    padding: 0px 20px;

    @media ${({ theme }) => theme.mobile} {
        padding: 0px 10px;
    }
`;

const NavigationTop = styled.div`
    display: flex;
    justify-content: end;
    padding: 0px 20px;
    margin-top: 10px;

    @media ${({ theme }) => theme.mobile} {
        padding: 0px 10px;
    }
`;

const NavigationHeader = styled.span`
    font-size: 17px;
    font-weight: 800;
    margin-top: 30px;

    @media ${({ theme }) => theme.mobile} {
        font-size: 14px;
    }
`;

const NavigationHeaderIcon = styled(NavigationHeader)`
    margin-top: 0px;
    margin-right: 10px;

    @media ${({ theme }) => theme.mobile} {
        margin-right: 5px;
    }
`;

const NavigationButton = styled(ButtonSpan)`
    font-size: 15px;
    margin-top: 10px;
    margin-left: 25px;
    a {
        color: ${(props) => props.theme.fontDarkGray};
        text-decoration: none;
    }

    @media ${({ theme }) => theme.mobile} {
        font-size: 13px;
        margin-left: 5px;
    }
`;

const Main = styled.div``;

export function Console() {
    const me = useMember();
    const system = useSystem();
    const [navigationMinimize, setNavigationMinimize] = useState(
        isMinimizeNavigationBar,
    );
    const navigationWidth = useNavigationWidth();

    const [isSignUp, setIsSignUp] = useState(false);
    const [canChangeActive, setCanChangeAction] = useState(false);
    const [showSettingMessagePopup, setShowSettingMessagePopup] =
        useState(false);
    const [showSettingKakaoPopup, setShowSettingKakaoPopup] = useState(false);
    const [showAccountPopup, setShowAccountPopup] = useState(false);
    const [showSystemCancelPopup, setShowSystemCancelPopup] = useState(false);
    const [showPhoneCertPopup, setShowPhoneCertPopup] = useState(false);

    const location = useLocation();
    const hasRefresh = useReactiveVar(hasRefreshIntervalId());

    useEffect(() => {
        const AUTO_REFRESH_TIME = 10 * 60 * 1000;
        //{AUTO_REFRESH_TIME}분에 한번 자동 새로고침

        if (hasRefresh === false) {
            const intervalId = setInterval(() => {
                evictCacheRootQuery();
            }, AUTO_REFRESH_TIME);

            setRefreshIntervalId(Number(intervalId));
        }
    }, [hasRefresh]);

    const [updateActionMutation] = useMutation<updateMemberActive>(
        UPDATE_MEMBER_ACTIVE_MUTATION,
        {
            onCompleted: (data: updateMemberActive) => {
                const {
                    updateMemberActive: { ok, error },
                } = data;

                if (ok) {
                    evictCacheRootQuery();
                    setCanChangeAction(false);
                } else {
                    alert(error);
                }
            },
        },
    );

    const getConsoleUI = () => {
        let result = null;
        if (location.pathname.includes(consoleRoutes.bookings)) {
            result = <Bookings />;
        } else if (location.pathname.includes(consoleRoutes.bookingsDay)) {
            result = <Bookings />;
        } else if (location.pathname.includes(consoleRoutes.bookingsCalendar)) {
            result = <Bookings />;
        } else if (location.pathname.includes(consoleRoutes.messages)) {
            result = <Messages />;
        } else if (
            location.pathname.includes(consoleRoutes.statistics_bookings)
        ) {
            result = (
                <StatisticsBookings navigationMinimize={navigationMinimize} />
            );
        } else if (
            location.pathname.includes(consoleRoutes.statistics_bookings_day)
        ) {
            result = (
                <StatisticsBookings navigationMinimize={navigationMinimize} />
            );
        } else if (
            location.pathname.includes(
                consoleRoutes.statistics_bookings_calendar,
            )
        ) {
            result = (
                <StatisticsBookings navigationMinimize={navigationMinimize} />
            );
        } else if (
            location.pathname.includes(consoleRoutes.change_member_password)
        ) {
            result = <MemberPasswordChange />;
        } else if (
            location.pathname.includes(consoleRoutes.update_hide_product)
        ) {
            if (me?.plusItem?.useHideProduct === true) {
                result = <UpdateHideProducts />;
            } else {
                result = <Navigate replace to={consoleRoutes.bookingsDay} />;
            }
        } else if (location.pathname.includes(consoleRoutes.kakao)) {
            result = <Kakao />;
        } else {
            result = <Navigate replace to={consoleRoutes.bookingsDay} />;
        }

        return result;
    };

    useEffect(() => {
        if (me && me !== undefined) {
            setIsSignUp(isMemberSignUp(me.signUpCompleteStep));
            if (me.memberPaymentInfo) {
                if (me.memberStatus === MemberStatus.INACTIVE) {
                    if (me.memberPaymentInfo.nextPaymentDate === null) {
                        setCanChangeAction(true);
                        onClickActive();
                    } else {
                        if (
                            me.inactiveReason === MemberInactiveReason.DEFAULT
                        ) {
                            setCanChangeAction(true);
                            onClickActive();
                        }
                    }
                }
            }
        }
    }, [me]);

    const onClickActive = () => {
        let canActive = false;
        if (me?.memberPaymentInfo) {
            if (me.memberStatus === MemberStatus.INACTIVE) {
                if (me.memberPaymentInfo.nextPaymentDate === null) {
                    canActive = true;
                } else {
                    if (me.inactiveReason === MemberInactiveReason.DEFAULT) {
                        canActive = true;
                    }
                }
            }
        }

        if (canActive) {
            let freePeriod = 14;

            if (system) {
                freePeriod = system.freePeriod;
            }
            const ok = window.confirm(
                `회원님의 예약/관리를 활성화 하려고합니다.\n확인을 하시면 자동 예약 문자 전송 등 예약/관리가 가능해지시고 처음 이용하시는 회원님이시면 ${freePeriod}일 동안 무료로 사용해보실 수 있습니다. 계속 진행하시겠습니까?`,
            );

            if (ok) {
                updateActionMutation();
            }
        }
    };

    const paymentDate = () => {
        let result = "";

        if (me?.memberPaymentInfo?.nextPaymentDate) {
            const date = new Date(Number(me.memberPaymentInfo.nextPaymentDate));
            result = `${String(date.getFullYear()).slice(-2)}/${String(
                date.getMonth() + 1,
            ).padStart(2, "0")}/${String(date.getDate()).padStart(2, "0")}`;
        }

        return result;
    };

    return (
        <Container>
            <PageTitle title="콘솔" />
            {isSignUp ? (
                <Banner
                    text="가입을 완료하시면 서비스를 이용하실 수 있습니다."
                    linkTo={routes.signup}
                    buttonText="가입하러 가기"
                />
            ) : me ? (
                <Body>
                    <Navigation navigationWidth={navigationWidth}>
                        {navigationMinimize === false ? (
                            <NavigationTop>
                                <NavigationButton
                                    onClick={() => {
                                        toggleNavigationMinimize();
                                        setNavigationMinimize(
                                            isMinimizeNavigationBar(),
                                        );
                                    }}
                                >
                                    <FontAwesomeIcon icon={faAnglesLeft} />
                                </NavigationButton>
                            </NavigationTop>
                        ) : (
                            <NavigationTop
                                style={{
                                    justifyContent: "center",
                                    padding: "0px",
                                }}
                            >
                                <NavigationButton
                                    style={{ margin: "0px", marginTop: "10px" }}
                                    onClick={() => {
                                        toggleNavigationMinimize();
                                        setNavigationMinimize(
                                            isMinimizeNavigationBar(),
                                        );
                                    }}
                                >
                                    <FontAwesomeIcon icon={faAnglesRight} />
                                </NavigationButton>
                            </NavigationTop>
                        )}

                        {navigationMinimize === false ? (
                            <NavigationInner>
                                <NavigationHeader>
                                    <NavigationHeaderIcon>
                                        <FontAwesomeIcon icon={faClock} />
                                    </NavigationHeaderIcon>
                                    예약
                                </NavigationHeader>

                                <NavigationButton>
                                    <Link to={consoleRoutes.bookingsDay}>
                                        예약 현황
                                    </Link>
                                </NavigationButton>
                                <NavigationButton>
                                    <Link
                                        to={
                                            consoleRoutes.statistics_bookings_day
                                        }
                                    >
                                        예약 통계
                                    </Link>
                                </NavigationButton>

                                {me.plusItem?.useBookingMessage === true ? (
                                    <>
                                        <NavigationHeader>
                                            <NavigationHeaderIcon>
                                                <FontAwesomeIcon
                                                    icon={faMessage}
                                                />
                                            </NavigationHeaderIcon>
                                            문자
                                        </NavigationHeader>
                                        {me.plusItem.useKakaoMessage ? (
                                            <NavigationButton
                                                onClick={() =>
                                                    setShowSettingKakaoPopup(
                                                        true,
                                                    )
                                                }
                                            >
                                                카카오톡
                                            </NavigationButton>
                                        ) : (
                                            <NavigationButton
                                                onClick={() =>
                                                    setShowSettingMessagePopup(
                                                        true,
                                                    )
                                                }
                                            >
                                                문자 설정
                                            </NavigationButton>
                                        )}

                                        <NavigationButton>
                                            <Link to={consoleRoutes.messages}>
                                                전송 현황
                                            </Link>
                                        </NavigationButton>
                                    </>
                                ) : null}

                                <NavigationHeader>
                                    <NavigationHeaderIcon>
                                        <FontAwesomeIcon icon={faCreditCard} />
                                    </NavigationHeaderIcon>
                                    결제
                                </NavigationHeader>
                                <NavigationButton onClick={onClickActive}>
                                    {canChangeActive
                                        ? "[예약/관리] 활성화"
                                        : `결제일: ${paymentDate()}`}
                                </NavigationButton>
                                <NavigationButton
                                    onClick={() => setShowAccountPopup(true)}
                                >
                                    결제 방법
                                </NavigationButton>
                                <NavigationButton
                                    onClick={() =>
                                        openUrlNewTab(routes.saleItems)
                                    }
                                >
                                    요금표
                                </NavigationButton>
                                <NavigationHeader>
                                    <NavigationHeaderIcon>
                                        <FontAwesomeIcon icon={faUser} />
                                    </NavigationHeaderIcon>
                                    MY
                                </NavigationHeader>
                                <NavigationButton>
                                    <Link
                                        to={
                                            consoleRoutes.change_member_password
                                        }
                                    >
                                        ONBI 비밀번호 변경
                                    </Link>
                                </NavigationButton>
                                <NavigationButton
                                    onClick={() => {
                                        setShowPhoneCertPopup(true);
                                    }}
                                >
                                    인증서류 전송
                                </NavigationButton>
                                {me.plusItem?.useHideProduct === true ? (
                                    <NavigationButton>
                                        <Link
                                            to={
                                                consoleRoutes.update_hide_product
                                            }
                                        >
                                            방 숨기기 관리
                                        </Link>
                                    </NavigationButton>
                                ) : null}
                            </NavigationInner>
                        ) : null}
                    </Navigation>
                    <Main>{getConsoleUI()}</Main>
                </Body>
            ) : null}
            {showSettingMessagePopup ? (
                <MessageSettingPopup
                    viewer={false}
                    didClosePopup={() => setShowSettingMessagePopup(false)}
                    didSuccessSubmit={() => setShowSettingMessagePopup(false)}
                />
            ) : null}
            {showSettingKakaoPopup ? (
                <KakaoMessagePopup
                    didClosePopup={() => setShowSettingKakaoPopup(false)}
                />
            ) : null}
            {showAccountPopup && me ? (
                <AccountInfoPopup
                    me={me}
                    didClosePopup={() => setShowAccountPopup(false)}
                />
            ) : null}

            {showSystemCancelPopup && me ? (
                <SystemCancelRegScheduleBookingPopup
                    didClosePopup={() => setShowSystemCancelPopup(false)}
                />
            ) : null}
            {showPhoneCertPopup ? (
                <PhoneCertPopup
                    didClosePopup={() => setShowPhoneCertPopup(false)}
                />
            ) : null}
            {isIOS() ? <RefreshButton /> : null}
        </Container>
    );
}
