import styled from "styled-components";
import { Popup } from "./Popup";
import { getKRDate } from "../bookings/BookingsCalendarMonthBooking";

interface IKakaoMessagePopup {
    didClosePopup: () => void;
    franchiseName: string;
    productName: string;
    url: string;
    isProductMessage: boolean;
}

const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 225px; /* Adjusted width to look more like a chat bubble */
    padding: 20px;
    border: 1px solid #e0e0e0;
    border-radius: 10px;
    background-color: #b9cbd9;
`;

const TextContainer = styled.div`
    display: flex;
    flex-direction: column;
    font-size: 13px;
    color: #333;
    padding: 10px;
`;

const TextArea = styled.pre`
    white-space: pre-wrap;
    word-wrap: break-word;
    margin: 0;
`;

const MessageContainer = styled.div`
    border-radius: 10px;
    background-color: #ffffff;
    margin-top: 5px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
`;

const MessageTitleContainer = styled.div`
    height: 35px;
    background-color: #fde81e;
    border-radius: 10px 10px 0 0;
    display: flex;
    align-items: center;
    padding-left: 10px;
`;

const MessageTitle = styled.div`
    font-size: 13px;
`;

const MessageEmphasisContainer = styled.div`
    padding-left: 10px;
    padding-top: 15px;
    padding-bottom: 15px;
    border-bottom: 1px solid lightgray;
`;

const MessageEmphasis = styled.div`
    font-size: 13px;
    margin-bottom: 5px;
    color: gray;
`;

const MessageEmphasisText = styled.div`
    font-size: 18px;
    font-weight: bold;
`;

export function KakaoMessagePreviewPopup({
    didClosePopup,
    franchiseName,
    productName,
    url,
    isProductMessage,
}: IKakaoMessagePopup) {
    const makeCreateDate = (date: Date) => {
        const krDate = getKRDate(date);

        const result = `${krDate.getFullYear()}-${String(
            krDate.getMonth() + 1,
        ).padStart(2, "0")}-${String(krDate.getDate()).padStart(
            2,
            "0",
        )} ${String(krDate.getHours()).padStart(2, "0")}:00`;

        return result;
    };

    let str = `안녕하세요, ${franchiseName} 입니다 ^^`;

    const startDate = new Date();
    const endDate = new Date();
    endDate.setHours(endDate.getHours() + 2);

    str += `\n예약하신 상품에 대한 안내사항입니다.`;
    str += `\n\n* 지점\n${franchiseName}, ${
        isProductMessage === true ? productName : "방 이름"
    }`;
    str += `\n\n* 이용일자\n${makeCreateDate(startDate)} ~\n${makeCreateDate(
        endDate,
    )}`;
    str += `\n\n★ 출입문 비밀번호, 이용 안내 등 자세한 안내 사항은 아래 [이용 안내] 링크를 눌러 확인해 주세요.`;
    str += `\n\n★ 만약 안내사항이 재전송 되었다면 링크 내용이 변경된 걸 수도 있습니다. 링크를 다시 한번 확인해 주세요.`;
    str += `\n\n${url}`;
    str += `\n\n💥 링크가 열리지 않을 경우 링크를 복사하여 크롬 등 다른 브라우저에서 실행해 보세요.`;
    str += `\n\n이용해 주셔서 감사합니다 :)`;

    return (
        <Popup
            didClosePopup={didClosePopup}
            maxWidth={"550px"}
            disabledBackgrounClickClose={true}
        >
            <Container>
                <MessageContainer>
                    <MessageTitleContainer>
                        <MessageTitle>알림톡 도착</MessageTitle>
                    </MessageTitleContainer>

                    <MessageEmphasisContainer>
                        <MessageEmphasis>예약 안내</MessageEmphasis>
                        <MessageEmphasisText>
                            예약이 완료되었습니다.
                        </MessageEmphasisText>
                    </MessageEmphasisContainer>

                    <TextContainer>
                        <TextArea>{str}</TextArea>
                    </TextContainer>
                </MessageContainer>
            </Container>
        </Popup>
    );
}
