import { useMutation } from "@apollo/client";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import styled from "styled-components";
import { evictCacheRootQuery, toWon } from "../../global";
import { CREATE_UPDATE_MEMBER_PAYMENT_INFO_MUTATION } from "../../graphqls/mutations";
import { useSystem } from "../../hooks/useMember";
import { lightTheme } from "../../styles";
import {
    createUpdateMemberPaymentInfo,
    createUpdateMemberPaymentInfoVariables,
} from "../../__generated__/createUpdateMemberPaymentInfo";
import { me_me_member } from "../../__generated__/me";
import { seeAllSaleItems_seeAllSaleItems_saleItems } from "../../__generated__/seeAllSaleItems";
import { Popup } from "./Popup";
import {
    PopupButton,
    PopupCell,
    PopupCellContentColumn,
    PopupCellContentRow,
    PopupCellInput,
    PopupCellInputContainer,
    PopupCellText,
    PopupCellTextContainer,
    PopupCellTitle,
    PopupCellTitleHighlight,
} from "./PopupCommon";

const Container = styled.div`
    display: flex;
    flex-direction: column;
    line-height: 120%;
    min-width: 300px;
`;
const Header = styled.span`
    font-size: 13px;
    font-weight: 700;
    color: ${(props) => props.theme.font};
    text-align: center;
    margin-bottom: 10px;
`;

const Footer = styled.span`
    font-size: 13px;
    color: ${(props) => props.theme.fontDarkGray};
    text-align: center;
    margin-top: 15px;
`;

const FormContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

interface IAccrountInfoProps {
    selectedItem?: seeAllSaleItems_seeAllSaleItems_saleItems;
    me?: me_me_member;
    didUpdateAccount?: () => void;
    didClosePopup: () => void;
}

interface IPaymentInfoForm {
    account_owner_name: string;
    account_business_number: string | null;
    account_business_name: string | null;
    account_boss_name: string | null;
    account_email: string | null;
}

export function AccountInfoPopup({
    didClosePopup,
    didUpdateAccount,
    selectedItem,
    me,
}: IAccrountInfoProps) {
    const item: { key?: string; name?: string } = {
        key: undefined,
        name: undefined,
    };

    const system = useSystem();

    const [isCreate, setIsCreate] = useState(false);
    const [showForm, setShowForm] = useState(false);
    const [costText, setCostText] = useState<string | null>(null);
    const [baseCostText, setBaseCostText] = useState<string | null>(null);
    const [addCostText, setAddCostText] = useState<string | null>(null);
    const [nextPaymentDate, setNextPaymentDate] = useState<Date | null>(null);

    useEffect(() => {
        if (!me) {
            setIsCreate(true);
            setShowForm(true);
        }
    }, [me]);

    useEffect(() => {
        if (me && system) {
            if (me.memberPaymentInfo) {
                const currentPaymentDate = new Date(
                    Number(me.memberPaymentInfo.nextPaymentDate),
                );
                const nextPaymentDate = new Date(currentPaymentDate.getTime());
                nextPaymentDate.setDate(system.settlementDay);

                nextPaymentDate.setMonth(nextPaymentDate.getMonth() + 1);

                if (currentPaymentDate.getDate() > system.settlementDay) {
                    nextPaymentDate.setMonth(nextPaymentDate.getMonth() + 1);
                }

                nextPaymentDate.setHours(23);
                nextPaymentDate.setMinutes(59);
                nextPaymentDate.setSeconds(59);
                nextPaymentDate.setMilliseconds(0);

                setNextPaymentDate(nextPaymentDate);
            }
        }
    }, [me, system]);

    useEffect(() => {
        if (me && system) {
            if (me.memberPaymentInfo) {
                let text = toWon(me.memberPaymentInfo.sumVATCost);
                const diffDay = me.memberPaymentInfo.diffDay;
                const dayCost = me.memberPaymentInfo.dayCost;

                if (me.memberPaymentInfo.vat !== 0) {
                    text = text + "(VAT 포함)";
                }

                setCostText(text);

                if (diffDay > 0) {
                    setBaseCostText(
                        `${toWon(me.memberPaymentInfo.memberCost)}`,
                    );
                    setAddCostText(` + ${toWon(dayCost)}(${diffDay}일)`);
                }
            }
        }
    }, [me, system]);

    if (selectedItem) {
        item.key = selectedItem.key;
        item.name = selectedItem.name;
    } else {
        if (me?.saleItem) {
            item.key = me.saleItem.key;
            item.name = me.saleItem.name;
        }
    }

    const {
        register,
        formState: { isValid },
        getValues,
    } = useForm<IPaymentInfoForm>({
        mode: "onChange",
        defaultValues: {
            account_owner_name: me?.memberPaymentInfo?.name,
            account_business_number: me?.memberPaymentInfo?.businessNumber,
            account_business_name: me?.memberPaymentInfo?.businessName,
            account_boss_name: me?.memberPaymentInfo?.bossName,
            account_email: me?.email,
        },
    });

    const onCompletedCreateUpdateMemberPaymentInfo = (
        data: createUpdateMemberPaymentInfo,
    ) => {
        const {
            createUpdateMemberPaymentInfo: { ok, error },
        } = data;

        if (ok) {
            if (isCreate === false) {
                alert("정보가 수정되었습니다.");
                evictCacheRootQuery();
            }
            if (didUpdateAccount) {
                didUpdateAccount();
            }
        } else {
            alert(error);
        }
    };

    const [createUpdateMemberPaymentInfoMutation] = useMutation<
        createUpdateMemberPaymentInfo,
        createUpdateMemberPaymentInfoVariables
    >(CREATE_UPDATE_MEMBER_PAYMENT_INFO_MUTATION, {
        onCompleted: onCompletedCreateUpdateMemberPaymentInfo,
    });

    const onClickCreateUpdate = () => {
        if (isValid) {
            const name = getValues("account_owner_name");
            const email = getValues("account_email");
            const businessNumber = getValues("account_business_number");
            const businessName = getValues("account_business_name");
            const bossName = getValues("account_boss_name");

            createUpdateMemberPaymentInfoMutation({
                variables: {
                    saleItemKey: item.key,
                    name,
                    email,
                    businessNumber,
                    businessName,
                    bossName,
                    updateCost: false,
                },
            });
        }
    };

    const paymentDate = (date: Date) => {
        const result = `${String(date.getFullYear())}년 ${String(
            date.getMonth() + 1,
        ).padStart(2, "0")}월 ${String(date.getDate()).padStart(2, "0")}일`;

        return result;
    };

    return (
        <Popup didClosePopup={didClosePopup}>
            <Container>
                {isCreate === false ? (
                    <>
                        {me?.memberPaymentInfo?.nextPaymentDate ? (
                            <PopupCell>
                                <PopupCellTitle>결제일</PopupCellTitle>
                                <PopupCellContentRow>
                                    <PopupCellText>
                                        {paymentDate(
                                            new Date(
                                                Number(
                                                    me.memberPaymentInfo
                                                        .nextPaymentDate,
                                                ),
                                            ),
                                        )}
                                    </PopupCellText>
                                </PopupCellContentRow>
                            </PopupCell>
                        ) : null}

                        {nextPaymentDate ? (
                            <PopupCell>
                                <PopupCellTitle>다음 결제일</PopupCellTitle>
                                <PopupCellContentRow>
                                    <PopupCellText>
                                        {paymentDate(nextPaymentDate)}
                                    </PopupCellText>
                                </PopupCellContentRow>
                            </PopupCell>
                        ) : null}

                        <PopupCell>
                            <PopupCellTitle>결제계좌</PopupCellTitle>
                            <PopupCellContentColumn>
                                <PopupCellText>우리은행</PopupCellText>
                                <PopupCellText>
                                    1005-004-257147 (주)폰티스
                                </PopupCellText>
                            </PopupCellContentColumn>
                        </PopupCell>
                        {costText ? (
                            <PopupCell>
                                <PopupCellTitle>결제금액</PopupCellTitle>
                                <PopupCellContentColumn>
                                    <PopupCellText>{costText}</PopupCellText>
                                    {baseCostText && addCostText ? (
                                        <PopupCellTextContainer>
                                            <PopupCellText>
                                                {baseCostText}
                                            </PopupCellText>
                                            <PopupCellText
                                                style={{
                                                    whiteSpace: "pre-wrap",
                                                    color: lightTheme.red,
                                                }}
                                            >
                                                {addCostText}
                                            </PopupCellText>
                                        </PopupCellTextContainer>
                                    ) : null}
                                </PopupCellContentColumn>
                            </PopupCell>
                        ) : null}

                        {showForm === false ? (
                            <PopupButton onClick={() => setShowForm(true)}>
                                내 결제 정보 보기
                            </PopupButton>
                        ) : null}
                    </>
                ) : null}
                {showForm === true ? (
                    <FormContainer>
                        <Header
                            style={{
                                marginTop: isCreate === false ? "30px" : "0px",
                            }}
                        >
                            결제하실 계좌의 예금주와 이메일을 입력해주세요.
                        </Header>
                        <PopupCell>
                            <PopupCellTitle>
                                예금주
                                <PopupCellTitleHighlight
                                    style={{ marginLeft: "3px" }}
                                >
                                    *
                                </PopupCellTitleHighlight>
                            </PopupCellTitle>
                            <PopupCellContentRow>
                                <PopupCellInputContainer>
                                    <PopupCellInput
                                        {...register("account_owner_name", {
                                            required: true,
                                        })}
                                    ></PopupCellInput>
                                </PopupCellInputContainer>
                            </PopupCellContentRow>
                        </PopupCell>
                        <PopupCell>
                            <PopupCellTitle>
                                이메일
                                <PopupCellTitleHighlight
                                    style={{ marginLeft: "3px" }}
                                >
                                    *
                                </PopupCellTitleHighlight>
                            </PopupCellTitle>
                            <PopupCellContentRow>
                                <PopupCellInputContainer>
                                    <PopupCellInput
                                        {...register("account_email", {
                                            required: true,
                                        })}
                                    ></PopupCellInput>
                                </PopupCellInputContainer>
                            </PopupCellContentRow>
                        </PopupCell>

                        <Header style={{ marginTop: "30px" }}>
                            세금 계산서가 필요하신 경우 아래 양식을
                            작성해주세요.
                        </Header>

                        <PopupCell>
                            <PopupCellTitle>등록번호</PopupCellTitle>
                            <PopupCellContentRow>
                                <PopupCellInputContainer>
                                    <PopupCellInput
                                        {...register(
                                            "account_business_number",
                                            {},
                                        )}
                                    ></PopupCellInput>
                                </PopupCellInputContainer>
                            </PopupCellContentRow>
                        </PopupCell>
                        <PopupCell>
                            <PopupCellTitle>대표성함</PopupCellTitle>
                            <PopupCellContentRow>
                                <PopupCellInputContainer>
                                    <PopupCellInput
                                        {...register("account_boss_name", {})}
                                    ></PopupCellInput>
                                </PopupCellInputContainer>
                            </PopupCellContentRow>
                        </PopupCell>
                        <PopupCell>
                            <PopupCellTitle>상호명</PopupCellTitle>
                            <PopupCellContentRow>
                                <PopupCellInputContainer>
                                    <PopupCellInput
                                        {...register(
                                            "account_business_name",
                                            {},
                                        )}
                                    ></PopupCellInput>
                                </PopupCellInputContainer>
                            </PopupCellContentRow>
                        </PopupCell>
                        {item.name ? (
                            <Footer>{`선택하신 상품: ${item.name}`}</Footer>
                        ) : null}
                        <PopupButton
                            disabled={!isValid}
                            onClick={onClickCreateUpdate}
                        >
                            {isCreate ? "등록" : "수정"}
                        </PopupButton>
                    </FormContainer>
                ) : null}
            </Container>
        </Popup>
    );
}
